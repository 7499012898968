
import React from 'react'
import DirectoryInfo from './DirectoryInfo'
import { Link } from 'gatsby';
import icon2 from '../../assets/images/icons/icon2.png'

const Theme1 = ({ contacts, directory }) => {
    const { directories } = directory

    return (
        <>
            {directories.length > 0 ?
                <section className='directories-area pb-70'>
                    <div className='container'>
                        <div className='row'>
                            {directory.directories.sort((a, b) => a.order - b.order).map((directory) => {
                                return (
                                    <DirectoryInfo
                                        directory={directory}
                                        key={directory.id}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </section>
                :
                null
            }
            {contacts.length > 0 ?
                <section className='contacts-area pt-50 pb-70'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12 mt-3'>
                                <div className='document-content'>
                                    <div className='content'>
                                        <div className='row'>
                                            {contacts.map((contact) => {
                                                return (
                                                    <div className='col-md-6 col-12 mb-3'>
                                                        <div className='card h-100 medias-list'>
                                                            <Link to={'/contact?slug=' + contact.slug} target='_blank'>
                                                                <img src={icon2} alt='banner' width={58} />
                                                                <div>
                                                                    <h5>{contact.last_name ? contact.last_name + ',' : ''} {contact.first_name}</h5>
                                                                    {contact.address ?
                                                                        <p className='mb-0'>{contact.address}</p>
                                                                        : null
                                                                    }
                                                                    {contact.phone ?
                                                                        <p className='mb-0'>{contact.phone}</p>
                                                                        : null
                                                                    }
                                                                    {contact.email ?
                                                                        <p className='mb-0'>{contact.email}</p>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                :
                null
            }
        </>
    )
}

export default Theme1