
import React, { useState } from 'react'
import Theme1 from './Theme1'
import Theme2 from './Theme2'

const DirectoryContent = ({ directory }) => {
    const { directories, theme } = directory
    const [contacts, setContacts] = useState([])

    const getContacts = () => {
        if (directory.contacts) setContacts(directory.contacts)
        if (directories.length > 0) {
            directories.map((item) => {
                return item.contacts.map((contact) => {
                    return contacts.push(contact)
                })
            })
        }
        contacts.sort((a, b) => a.order - b.order)
    }

    if (contacts.length === 0) getContacts()

    const getTheme = () => {
        switch (theme) {
            case 'theme1': return <Theme1 directory={directory} contacts={contacts} />
            case 'theme2': return <Theme2 directory={directory} contacts={contacts} />

            default: return null
        }
    }


    return (
        <section className='page-area ptb-50'>
            <div className='container'>
                <div className='row'>
                    {getTheme()}
                </div>
            </div>
        </section>
    )
}

export default DirectoryContent