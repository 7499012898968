
import React from 'react'
import ContactInfo from './ContactInfo'
import DirectoryInfo from './DirectoryInfo'

const Theme1 = ({ contacts, directory }) => {
    const { directories } = directory

    return (
        <>
            {directories && directories.length > 0 ?
                <section className='directories-area pb-70'>
                    <div className='container'>
                        <div className='row'>
                            {directories.sort((a, b) => a.order - b.order).map((directory) => {
                                return (
                                    <DirectoryInfo
                                        directory={directory}
                                        key={`directory-${directory.id}`}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </section>
                :
                null
            }
            {contacts.length > 0 ?
                <section className='contacts-area pt-50 pb-70'>
                    <div className='container'>
                        <div className='row'>
                            {contacts.map((contact) => {
                                return (
                                    <ContactInfo
                                        contact={contact}
                                        directory={directory}
                                        key={`contact-${contact.id}`}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </section>
                :
                null
            }
        </>
    )
}

export default Theme1