import React from 'react'
import { Link } from 'gatsby'

const DirectoryInfo = ({ directory }) => {
    return (
        <div className='col-lg-4 col-sm-6 mb-3'>
            <div className='h-100'>
                <Link to={'/directory?slug=' + directory.slug}>
                    <div className='single-directory-box h-100'>
                        <div className='icon'>
                            {directory.media ?
                                <img src={directory.media[0].original_url} alt={directory.name} />
                                :
                                null
                            }
                        </div>
                        <h3>
                            {directory.name} ({directory.contacts.length})
                        </h3>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default DirectoryInfo