import React, { useEffect, useState } from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import DirectoryContent from '../components/Directory/DirectoryContent'
import { getDirectory } from '../api/directory'
import { navigate } from 'gatsby'

const Directories = ({ location }) => {
    const [directory, setDirectory] = useState(null)

    const params = new URLSearchParams(location.search)
    const slug = params.get('slug')

    useEffect(() => {
        (async () => {
            try {
                setDirectory(null)
                const data = await getDirectory(slug)
                setDirectory(data)
            } catch (error) {
                setDirectory(null)
                navigate('/404')
            }
        })()
    }, [slug])

    if (!directory) return null

    return (
        <Layout title={directory.name}>
            <PageBanner
                pageTitle={directory.name}
                homePageText={directory.parent ? directory.parent.name : 'Inicio'}
                homePageUrl={directory.parent ? `/directory?slug=${directory.parent.slug}` : '/'}
                activePageText={directory.name}
            />
            <DirectoryContent
                directory={directory}
            />
        </Layout>
    )
}

export default Directories
